<template>
  <div>
    <div v-for="item in list" :key="item.id" @click="checked(item)">
      <van-swipe-cell>
        <div class="list">
          <div class="flex">
            <div class="left">
              <span class="name">{{ item.consignee }} </span> <span>{{ item.moblie }}</span>
              <div v-if="item.isdefault==='1'" class="default">
                默认
              </div>
            </div>
            <!--            <van-col span="10" class="size28">-->
            <!--              <span class="name">{{ item.consignee }} </span> {{ item.moblie }}-->
            <!--            </van-col>-->
            <!--            <van-col span="10">-->
            <!--              <van-tag v-if="item.isdefault=='1'" round type="primary" size="large">-->
            <!--                默认-->
            <!--              </van-tag>-->

            <!--            </van-col>-->
            <div class="right">
              <img src="./img/edit.png" class="edit-ico" alt="" @click.stop="edit(item)">
            </div>
            <!--            <van-col span="4" class="right-align">-->
            <!--              <img src="./img/edit.png" class="edit-ico" alt="" @click.stop="edit(item)">-->
            <!--            </van-col>-->
          </div>
          <van-row>
            <van-col span="24" class="size28">
              <p>{{ item.dpmc }} {{ item.dcmc }}{{ item.ddmc }}{{ item.xxdz }}</p>
            </van-col>
          </van-row>
        </div>
        <template #right>
          <van-button square text="删除" type="danger" class="delete-button" @click="del(item.id)" />
        </template>
      </van-swipe-cell>
    </div>
    <div class="footer">
      <van-button type="info" round size="small" class="fixedBtn" @click="add">
        新增收货地址
      </van-button>
    </div>
  </div>
</template>
<script>
import { userModel } from '@/api/user'
export default {
  name:'AddressList',
  data(){
    return{
      chosenAddressId:'1',
      list: [],
      flag:''
    }
  },
  mounted(){
    this.getList()
    this.flag = this.$route.query.flag
  },
  methods: {
    getList(){
      userModel.addressList().then(res=>{
        this.list = res.data
      })
    },
    add() {
      this.$router.push('/addressAdd?type=0')
    },
    edit(obj){
      obj.area = obj.dpmc+" "+obj.dcmc+obj.ddmc
      localStorage.setItem("address",JSON.stringify(obj))
      this.$router.push('/addressEdit?type=1')
    },
    del(id){
      userModel.addressDel(id).then(res=>{
        if(res.code == 200){
          this.getList()
          this.$toast({message:'删除成功！'})
        }
      })
    },
    checked(item){
      if(this.flag=='cf'){
        localStorage.setItem('address',JSON.stringify(item))
        //this.$router.push("/pay")
        this.$router.back()
      }
    }

  }
}
</script>
<style scoped lang="less">
 @import "../../assets/styles/variable.less";

.list{
  width: 100%;
  height: 168px;
  padding: 32px;
  border-bottom: 1px solid #EEEEEE;
  box-sizing: border-box;
  .flex{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .left{
      display: flex;
      flex-direction: row;
      align-items: center;
      flex:1;
      .name{
        font-size: 30px;
        color: #3D3D3D;
        font-weight: bold;
        margin-right: 16px;
      }
      .default{
        margin-left:16px;
        width:80px;
        height:32px;
        border-radius: 16px;
        background:#3A8AE5;
        color:#fff;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
    }

  }

}
.right-align{
  text-align: right;
}
.edit-ico{
  width: 32px;
  height: 32px;
}
.footer{
  background: #FFFFFF;
}
.van-button--danger{
  height: 100%;
}
</style>
